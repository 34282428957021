DCMS.GUI = {

    sort_folder: false,
    sort_media: true,
    sort_pages: false,

    // *** contains field_id or field_name thas are modified
    modified_fields: {},

    // *** Flag to indicate if the form was submitted
    submit_enabled: false,

    init: function () {
        'use strict';

        this.Login.init();
        this.Dialog.init();
        this.Slider.init();
        this.Tree.init();
        this.RCM.init();
        this.cbox.init();
        this.Help.init();
        this.TimePicker.init();
        this.DatePicker.init();
        this.Forms.init();
        this.Media.init();
        this.Pages.init();
        this.Editor.init();
        this.Tooltip.init();
        this.Plugins.init();
        this.Sort.init();
        this.AccessUserSelect.init();

        this.registerEvents();
    },

    /**
     * Check if GUI has modified fields
     *
     * @returns {boolean}
     */
    hasModifiedFields: function () {
        "use strict";
        return (DCMS.Utils.getObjectLength(this.modified_fields) > 0);
    },

    /**
     *  Set or unset the field as modified
     *
     * @param {string} field
     * @param {boolean} value
     */
    setFieldModified: function (field, value) {
        "use strict";

        if (value) {
            this.modified_fields[field] = value;
        } else {
            delete this.modified_fields[field];
        }
    },

    /**
     * Leave page and check if modified contents exist
     *
     * @param {Event} event
     * @returns {string}
     */
    leave: function (event) {
        "use strict";

        if (!DCMS.GUI.submit_enabled && DCMS.GUI.hasModifiedFields()) {
            event.preventDefault();
            //DCMS.Debug.log(DCMS.GUI.submit_enabled);
            return 'Modified ';
        }
    },

    registerEvents: function () {
        'use strict';

        $(window).on('beforeunload', DCMS.GUI.leave);

        $(document).on('keydown mousedown', function (ev) {
            DCMS.Session.setLastUserAction();
        });

        $('select[data-auto-submit="true"]').on('change', DCMS.Events.Global.autoSubmit);

        // *** Change Site Select
        /* GHA, 2016-07-28: use data-auto-submit
        $('#DCMS_selected_site_id, #DCMS-site-select').on(
            'change',
            DCMS.Events.Global.changeSite
        );
        */

        // *** Hide/Show fieldset section
        $('legend.DCMS_contentKey').on(
            'click',
            DCMS.Events.Global.toggleFieldset
        );

        // *** Prevent Multiple Submits
        $('form[data-prevent-multiple-submits!="off"]').on(
            'submit',
            DCMS.Events.Global.preventMultipleFormSubmits
        );

        // *** Init Select From Tree
        $('.DCMS_btn_searchTree, .DCMS_browse_pagetree').on(
            'click',
            DCMS.GUI.Tree.select
        );

        // *** Page: Change Page Type
        $('#form_page_type').on(
            'change',
            DCMS.Events.Pages.changePageType
        );

        // *** Page: Change Page Template Select
        $('#form_page_template').on(
            'change',
            DCMS.Events.Pages.changePageTemplate
        );

        // *** Delete ArrayForm Row
        $('.delFormArrayRow').on(
            'click',
            DCMS.GUI.Forms.ArrayForms.deleteRow
        );

        // *** Show Template Preview Image
        $('#form_page_template').find('option').on(
            'mouseover mouseout',
            DCMS.Events.Pages.changePageTemplatePreview
        );

        // *** PAGES: Change Plugin Select
        $(document).on(
            'change',
            '#DCMS_selectPlugin',
            DCMS.Events.Pages.changePlugin
        );

        // ***
        $('#DCMS_pluginSetting_type_id').on(
            'change',
            DCMS.Events.Pages.changePluginSetting
        );

        // *** Load Help Content
        $('a.DCMS_helpItem').on(
            'click',
            DCMS.Events.Help.loadHelpContent
        );

        // *** Check all checkboxes
        $('input.DCMS_checkAll:checkbox').on(
            'click',
            DCMS.Events.Global.checkAll
        );

        // *** Check all checkboxes
        $('input.DCMS_checkAllCBValue:checkbox').on(
            'click',
            DCMS.Events.Global.checkAllByName
        );

        // *** Check all checkboxes
        $('input.DCMS_checkSelectSyncValue:checkbox').on(
            'click',
            DCMS.Events.Global.syncSelectsByName
        );

        // *** Open Debug Popin by Shift-Ctrl-d
        $(document).on('keypress', function (ev) {

            // *** Open Debug Window with "Shift-Ctrl-d" (68=D)
            if (ev.ctrlKey && ev.which == 68) {
                var debug_link = $('a.cbox_debugInfo, a#DCMS_showDebugInfo');
                ev.preventDefault();
                debug_link.trigger('click');
            }
        });
    },

    updatePageTemplatePreview: function () {
        'use strict';

        var form = $('#form_page_template');
        var selected_template_id = form.val();
        var data_preview_img = $('option[value=' + selected_template_id + ']', form).attr('data-preview-img');

        var curr = $('span#DCMS_template_preview_current');
        if (typeof data_preview_img !== 'undefined') {
            $('a', curr).attr('href', data_preview_img);
            $('img', curr).attr('src', data_preview_img);
        } else {
            $('a', curr).attr('href', DCMS.theme_files.img.no_preview);
            $('img', curr).attr('src', DCMS.theme_files.img.no_preview).attr('alt', 'No preview available');
        }

        DCMS.Debug.log('Update Page Template Preview: ' + selected_template_id);
    }

};

DCMS.GUI.Login = {

    elem: null,

    init: function () {
        "use strict";

        this.elem = $('#DCMS_loginBox');
        if (this.elem.length > 0) {
            this.setFocus();
        }
    },

    setFocus: function () {
        "use strict";

        this.elem.find('input[name="user_name"]').focus();
    }
};

DCMS.GUI.Dialog = {
    elem: null,

    init: function () {
        "use strict";

        this.elem = $('#DCMS-uiDialog');
    },

    setMessage: function (msg) {
        this.elem.html(msg);
    },

    open: function (options) {
        this.elem.dialog(options);
    }
};

/**
 * Content Editor tinyMCE or CKEditor
 *
 * @type {Object}
 */
DCMS.GUI.Editor = {

    name: '',
    instances: {},
    selectors: {},

    init: function () {
        "use strict";
        // *** Determine Editor version
        if (typeof CKEDITOR != 'undefined') {
            this.name = 'CKEditor';
            DCMS.Debug.log('ckEditor: Auto-Inline-Editing is ' + (CKEDITOR.disableAutoInline ? 'off' : 'on'));
        } else if (typeof tinyMCE_config != 'undefined') {
            this.name = 'tinyMCE';
        }

        // *** Init different versions
        switch (this.name) {
            case 'tinyMCE':
                tinyMCE.init(tinyMCE_config);
                break;

            case 'CKEditor' :
                CKEDITOR.disableAutoInline = true;
                var htmlEditors = $("textarea[data-editor-selector]").filter('[contenteditable!="true"]');
                htmlEditors.each(function (idx, elem) {
                    var selector = $(elem).attr("data-editor-selector");
                    var editor_elem = $(elem).ckeditor(ckconfig[selector]);
                    var editor_inst = editor_elem.editor;
                    var editor_id = $(editor_inst).attr('id');
                    DCMS.GUI.Editor.instances[editor_id] = {
                        'name': $(editor_elem).attr('name'),
                        'selector': selector,
                        'instance': editor_inst,
                        'checksum': $(elem).attr('data-content-checksum'),
                        'checksum_js': hex_md5($(elem).val()),
                        'textarea': $(elem),
                        'is_modified': false
                    };

                    /*
                     DCMS.Debug.log('PHP:' + DCMS.GUI.Editor.instances[editor_id].checksum);
                     DCMS.Debug.log('JS :' + DCMS.GUI.Editor.instances[editor_id].checksum_js);
                     */

                    editor_inst.on('key', function () {
                        DCMS.Session.setLastUserAction();
                    });

                    editor_inst.on('change', function () {
                        var id = $(this).attr('id');
                        var inst = DCMS.GUI.Editor.instances[id];
                        var checksum_new = hex_md5(inst.textarea.val());
                        if (!inst.is_modified && inst.checksum != checksum_new) {
                            inst.is_modified = true;
                            DCMS.Debug.log('ckEditor content modified: ' + id);
                            DCMS.Debug.log(inst.checksum + ' ' + checksum_new);
                            inst.textarea.parents('.form-row').addClass('DCMS-modified');
                        } else if (inst.is_modified && inst.checksum == checksum_new) {
                            inst.is_modified = false;
                            DCMS.Debug.log('ckEditor content restored: ' + id);
                            DCMS.Debug.log(inst.checksum + ' ' + checksum_new);
                            inst.textarea.parents('.form-row').removeClass('DCMS-modified');
                        }
                    });
                });

                var dynamicEditors = $('div[contenteditable="true"][data-editor-selector]');
                if (dynamicEditors.length > 0) {

                    dynamicEditors.each(function (idx, elem) {
                        var selector = $(elem).attr('data-editor-selector');
                        var editor_id = $(elem).attr('id');
                        //CKEDITOR.inline(editor_id, ckconfig[selector]);
                    });
                }
                break;
        }
    },

    /**
     * Add Editor config files to theme_files to load at startup
     */
    addConfigsToThemeFiles: function () {
        "use strict";
        var htmlEditors = $("*[data-editor-selector]");
        htmlEditors.each(function (idx, elem) {
            var selector = $(elem).attr("data-editor-selector");
            DCMS.theme_files.js[selector] = DCMS.url2settings + 'ckeditor.' + selector + '.js';
        });
    }

};

DCMS.GUI.Media = {

    init: function () {
        'use strict';

        if ($('body').hasClass('media')) {
            this.FileUpload.init();
            this.Preview.init();
            this.ThumbnailPositioning.init();
            this.Sorting.init();
        }
    },

    FileUpload: {
        elem: null,

        init: function () {
            'use strict';

            this.elem = $('#fileupload');

            if (this.elem.length > 0) {
                DCMS.Debug.log('Init File-Upload');
                // Initialize the jQuery File Upload widget:
                this.elem.fileupload({
                    // Uncomment the following to send cross-domain cookies:
                    //xhrFields: {withCredentials: true},
                    url: '/media_upload.php?' + SID
                });

                // Enable iframe cross-domain access via redirect option:
                this.elem.fileupload(
                    'option',
                    'redirect',
                    window.location.href.replace(
                        /\/[^\/]*$/,
                        '/cors/result.html?%s'
                    )
                );

                if (window.location.hostname === 'blueimp.github.io') {
                    // Demo settings:
                    this.elem.fileupload('option', {
                        url: '//jquery-file-upload.appspot.com/',
                        // Enable image resizing, except for Android and Opera,
                        // which actually support image resizing, but fail to
                        // send Blob objects via XHR requests:
                        disableImageResize: /Android(?!.*Chrome)|Opera/
                            .test(window.navigator.userAgent),
                        maxFileSize: 999000,
                        acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i
                    });
                    // Upload server status check for browsers with CORS support:
                    if ($.support.cors) {
                        $.ajax({
                            url: '//jquery-file-upload.appspot.com/',
                            type: 'HEAD'
                        }).fail(function () {
                            $('<div class="alert alert-danger"/>')
                                .text('Upload server currently unavailable - ' +
                                    new Date())
                                .appendTo('#fileupload');
                        });
                    }
                } else {
                    // Load existing files:
                    this.elem.addClass('fileupload-processing');
                    $.ajax({
                        // Uncomment the following to send cross-domain cookies:
                        //xhrFields: {withCredentials: true},
                        url: this.elem.fileupload('option', 'url'),
                        dataType: 'json',
                        context: this.elem[0]
                    }).always(function () {
                        $(this).removeClass('fileupload-processing');
                    }).done(function (result) {
                        $(this).fileupload('option', 'done')
                            .call(this, $.Event('done'), {result: result});
                    });
                }

            }
        }
    },

    Preview: {

        elem: null,

        init: function () {
            'use strict';

            try {
                this.elem = $('a.DCMS_mediaPreview[rel]');
                if (this.elem.length > 0) {
                    this.elem.imgPreview({
                        imgCSS: {
                            'padding': '4px',
                            'background': '#e4e4e4',
                            'border-radius': '4px',
                            'border': '1px solid #999'
                        },
                        srcAttr: 'rel'
                    });
                }
            } catch (e) {
                DCMS.Debug.log('Init DCMS.GUI.Media.Preview failed: ' + e);
            }

        }
    },

    /**
     * Sort media files by drag and drop
     */
    Sorting: {

        media_list: null,
        folder_list: null,

        init: function () {
            "use strict";

            if (DCMS.GUI.sort_media) {
                this.media_list = $('.DCMS_objectList.sortable');
                var selected_media_order = $('a.DCMS_mediaOrderSelected').attr('data-media-order');
                if (selected_media_order == 'SA') {
                    this.media_list.sortable({
                        handle: '.DCMS_objectOverviewInfo',
                        placeholder: 'sortable-placeholder',
                        update: function () {
                            "use strict";
                            var sorted = $(this).sortable("toArray", {attribute: "data-media-id"});
                            DCMS.AJAX.sendApiRequest('sort', 'media', sorted, null);
                        }
                    });
                } else {
                    this.media_list.removeClass('sortable');
                }
            }

            if (DCMS.GUI.sort_folder) {
                this.folder_list = $('#DCMS_mediaTree').find('ul');

                this.folder_list.sortable({
                    placeholder: 'sortable-placeholder',
                    containment: 'parent',
                    items: '> li',
                    axis: 'y',
                    update: function () {
                        "use strict";

                        var sorted = $(this).sortable("toArray", {attribute: "data-tree-node-id"});
                        DCMS.AJAX.sendApiRequest('sort', 'folder', sorted, null, null);
                    }
                });
            }
        }

    },

    ThumbnailPositioning: {

        elem: null,
        container: null,
        radio: null,
        imgareaselect: null,

        data: {},
        pos: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0
        },

        init: function () {

            this.elem = $('#DCMS_mediaFormThumbnail');

            if (this.elem.length > 0) {
                try {
                    this.container = $('#DCMS_mediaFormThumbContainer', this.elem);
                    this.radio = $('#DCMS_mediaFormThumbPosition input[name=thumb_position]', this.elem);

                    this.data = {
                        thw: 140, // thumbnail-width
                        thh: 140, // thumbnail-height
                        imw: this.container.width(), // image width
                        imh: this.container.height(), // image height
                        pos: this.radio.filter(':checked').attr('data-css'), // position (center|top|right|bottom|left)
                        ori: (this.container.hasClass('portrait') ? 'portrait' : 'landscape') // orientation (landscape|portrait)
                    };

                    this.getPosition();

                    this.imgareaselect = $('img', this.container).imgAreaSelect({
                        handles: false,
                        movable: false,
                        instance: true,
                        resizable: false,
                        x1: this.pos.x1,
                        y1: this.pos.y1,
                        x2: this.pos.x2,
                        y2: this.pos.y2,
                        onSelectEnd: function () {
                            log('Select end');
                        }
                    });

                    this.radio.on('click', function (ev) {
                        var newpos = $(this).attr('data-css');
                        DCMS.GUI.Media.ThumbnailPositioning.updatePosition(newpos);
                    })
                } catch (e) {
                    DCMS.Debug.log('Init Media Thumbnail position failed: ' + e);
                }
            }
        },

        updatePosition: function (newpos) {
            "use strict";
            this.data.pos = newpos;
            this.getPosition();
            this.setPosition();
        },

        getPosition: function () {
            "use strict";
            var pos = {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 0
            };

            if (this.data.ori === 'portrait') {
                switch (this.data.pos) {
                    case 'left':
                        break;

                    case 'right':
                        break;

                    case 'top':
                        this.pos.x1 = 0;
                        this.pos.y1 = 0;
                        this.pos.x2 = this.data.thw;
                        this.pos.y2 = this.data.thh;
                        break;

                    case 'bottom':
                        this.pos.x1 = 0;
                        this.pos.y1 = this.data.imh - this.data.thh;
                        this.pos.x2 = this.data.thw;
                        this.pos.y2 = this.data.imh;
                        break;

                    case 'center':
                    default:
                        this.pos.x1 = Math.floor((this.data.imw - this.data.thw) / 2);
                        this.pos.y1 = Math.floor((this.data.imh - this.data.thh) / 2);
                        this.pos.x2 = this.data.imw - Math.ceil((this.data.imw - this.data.thw) / 2);
                        this.pos.y2 = this.data.imh - Math.ceil((this.data.imh - this.data.thh) / 2);
                        break;
                }

            } else {
                switch (this.data.pos) {
                    case 'left':
                        this.pos.x1 = 0;
                        this.pos.y1 = 0;
                        this.pos.x2 = this.data.thw;
                        this.pos.y2 = this.data.thh;
                        break;

                    case 'right':
                        this.pos.x1 = this.data.imw - this.data.thw;
                        this.pos.y1 = 0;
                        this.pos.x2 = this.data.imw;
                        this.pos.y2 = this.data.imh;
                        break;

                    case 'top':
                        break;

                    case 'bottom':
                        break;

                    case 'center':
                    default:
                        this.pos.x1 = Math.floor((this.data.imw - this.data.thw) / 2);
                        this.pos.y1 = 0;
                        this.pos.x2 = this.data.imw - Math.ceil((this.data.imw - this.data.thw) / 2);
                        this.pos.y2 = this.data.thh;
                        break;
                }
            }
        },

        setPosition: function () {
            "use strict";
            this.imgareaselect.setSelection(this.pos.x1, this.pos.y1, this.pos.x2, this.pos.y2);
            this.imgareaselect.setOptions({show: true});
            this.imgareaselect.update();
        }
    }

};

DCMS.GUI.Pages = {
    init: function () {
        "use strict";

        this.Contents.init();
        this.Sorting.init();
    },

    /**
     * Sort media files by drag and drop
     */
    Sorting: {

        sortable_list: null,

        init: function () {
            "use strict";

            if (DCMS.GUI.sort_pages) {
                this.sortable_list = $('#DCMS_pageTree').find('ul');

                this.sortable_list.sortable({
                    placeholder: 'sortable-placeholder',
                    containment: 'parent',
                    items: '> li',
                    axis: 'y',
                    update: function (ev, ui) {
                        "use strict";

                        var sorted = $(this).sortable("toArray", {attribute: "data-tree-node-id"});
                        DCMS.AJAX.sendApiRequest('sort', 'pages', sorted, null, null);
                    }
                });
            }
        }
    },

    Contents: {

        form: null,

        init: function () {
            "use strict";

            this.form = $('#DCMS-pageContentForm');

            if (this.form.length > 0) {
                this.initChecksum();
            }
        },

        initChecksum: function () {
            $(':input', this.form).on('change', function () {
                var checksum_old = $(this).attr('data-content-checksum');
                var field_id = $(this).attr('id') || $(this).attr('name') || '';
                var checksum_new = hex_md5($(this).val());
                if (checksum_new !== checksum_old) {
                    $(this).parents('.form-row').addClass('DCMS-modified');
                    DCMS.GUI.setFieldModified(field_id, true);
                } else {
                    $(this).parents('.form-row').removeClass('DCMS-modified');
                    DCMS.GUI.setFieldModified(field_id, false);
                }

                DCMS.Debug.log(field_id + ': ' + checksum_old + ' ' + checksum_new);
            });

            this.form.on('submit', function (event) {
                DCMS.GUI.submit_enabled = true;
                return true;
            });
        },

        isModified: function () {

        }

    }
};

DCMS.GUI.Plugins = {
    //*** Container for DCMS Edit-Plugins
    init: function () {
        if ($('body').hasClass('plugin')) {
            var plugin_name, plugin_obj;
            for (plugin_name in this) {
                if (this.hasOwnProperty(plugin_name) && typeof this[plugin_name] == "object") {
                    plugin_obj = this[plugin_name];
                    if (typeof plugin_obj.init == "function") {
                        plugin_obj.init();
                    }
                }
            }
        }
    }
};

DCMS.GUI.Forms = {

    init: function () {
        'use strict';

        this.GlobalForms.init();
        this.TabbedForms.init();
        this.SiteSettingsForm.init();
        this.PluginMediaForm.init();
        this.MediaRelationForm.init();
        DCMS.GUI.Tooltip.init('.DCMS-form label')
    }
};


DCMS.GUI.Forms.GlobalForms = {

    limited_fields: null,

    /**
     * Initialize GlobalForms
     */
    init: function () {
        let _this = this;
        DCMS.Localization.loadLocalization(['txt_gui_label_limit_fields_chars', 'txt_gui_label_limit_fields_words']).done(function () {
            _this.initCharWordCount();
            _this.registerEvents();
        });
    },

    /**
     * initialize the char-/word-count feature for inputs with appropriate data attributes
     */
    initCharWordCount: function () {
        let _this = this;

        this.limited_fields = $('input[type="text"], textarea').filter('[data-char-min],[data-char-max],[data-word-min],[data-word-max]');
        if (this.limited_fields.length > 0) {
            $.each(this.limited_fields, function (idx, el) {
                let limit_span = $('<span>').addClass('limits');
                limit_span.append($('<span>').addClass('char-count'));
                limit_span.append($('<span>').addClass('word-count'));
                $(this).after(limit_span);
                _this.updateCharWordCount($(el))
            });
        }
    },

    /**
     * Register events for global forms
     */
    registerEvents: function () {
        let _this = this;

        this.limited_fields.on('keyup', function (ev) {
            _this.updateCharWordCount(ev.target);
        });
    },

    /**
     * Update word- nd char-count for the given element
     *
     * @param {HTMLElement} el
     */
    updateCharWordCount: function (el) {
        let _this = this;
        let limit_type = $(el).data('limit-type') || 'soft';
        let char_min = $(el).data('char-min') || 0;
        let char_max = $(el).data('char-max') || 0;
        let word_min = $(el).data('word-min') || 0;
        let word_max = $(el).data('word-max') || 0;
        let char_count = null;
        let word_count = null;
        let limit_span = $(el).next('span.limits');
        let char_span = limit_span.find('span.char-count');
        let word_span = limit_span.find('span.word-count');

        if (char_max || char_min) {
            char_count = $(el).val().countChars();

            char_span.html(DCMS.Localization.getLocalization('txt_gui_label_limit_fields_chars') + ' (' + char_min + '-' + char_max + '): <b>' + char_count + '</b>').toggleClass('exceeded', (char_count > char_max || char_count < char_min));
        }

        if (word_min || word_max) {
            word_count = $(el).val().countWords();

            word_span.html(DCMS.Localization.getLocalization('txt_gui_label_limit_fields_words') + ' (' + word_min + '-' + word_max + '): <b>' + word_count + '</b>').toggleClass('exceeded', (word_count > word_max || word_count < word_min));
        }

        // *** Reset values on hard limit type
        if (limit_type === 'hard') {
            let text_val = $(el).val();
            if ((char_count) && char_count > char_max) {
                $(el).val(text_val.substring(0, char_max));
                _this.updateCharWordCount(el);
            }

            if ((word_count) && word_count > word_max) {
                $(el).val(text_val.split(' ', word_max).join(' '));
                _this.updateCharWordCount(el);
            }
        }
    },
};

DCMS.GUI.Forms.ArrayForms = {

    init: function () {
        'use strict';
    },

    addDeleteButton: function (row) {
        'use strict';

        $('div:first', row).append('<input type="button" value="" class="DCMS_btn delFormArrayRow" />');
        $('.delFormArrayRow', row).click(this.deleteRow);
    },

    deleteRow: function () {
        'use strict';

        var formArrayContainer = $(this).parents('.DCMS_formArray');
        var row = $(this).parents('.DCMS_formArrayRow');
        row.remove();
        $('.DCMS_formArrayRow label', formArrayContainer).first().css('visibility', 'visible');
        DCMS.GUI.Forms.ArrayForms.renumberRows(formArrayContainer);
    },

    renumberRows: function (formArrayContainer) {
        'use strict';

        $('.DCMS_formArrayRow', formArrayContainer).each(function (idx, row) {
            $('input[name]', row).each(function (idx2, inputField) {
                var name = $(inputField).attr('name');
                if (name.search(/\[\d\]$/i) >= 0) {
                    // *** Replace Array fields in Form Dummy by new Index
                    var newName = name.replace(/\[\d\]/i, '[' + idx + ']');
                    $(inputField).attr('name', newName);
                }
            });
        });
    }
};

DCMS.GUI.Forms.TabbedForms = {

    elem: null,

    init: function () {
        'use strict';

        try {
            this.elem = $('.DCMS_tabbedForm');

            var $tabbedForms = this.elem;
            var $tabMenus = $('ul.DCMS_tabMenu', $tabbedForms);

            $('a', $tabMenus).each(function (idx, elem) {
                if (idx > 0) {
                    var id = $(elem).attr('href').substr(1);
                    $tabbedForms.find('#' + id).hide();
                }
            });

            $('a[href^="#"]', $tabMenus).on('click', function (ev) {
                ev.preventDefault();
                $('a', $tabMenus).removeClass('DCMS_active');
                var id = $(this).attr('href').substr(1);
                $(this).addClass('DCMS_active').parents('form.DCMS_tabbedForm').find('div.DCMS_tabMenuContent').hide();
                $tabbedForms.find('#' + id).show();
            });
        } catch (e) {
            DCMS.Debug.log('Global: Init Tab-Menu failed!' + e);
        }
    }
};

DCMS.GUI.Forms.SiteSettingsForm = {
    elem: null,
    tmpl: null,

    init: function () {
        'use strict';

        this.elem = $('fieldset#DCMS_siteSettings');
        this.tmpl = $('div#DCMS_siteSettingDummy');

        if (!this.elem || !this.tmpl) {
            return;
        }

        // Remove Row
        $(document).on(
            'click',
            'input.delSiteSetting',
            function () {
                $(this).parents('div.form-field').detach();
                DCMS.GUI.Forms.SiteSettingsForm.renumberFormRows();
            }
        );

        // Add Row
        $(document).on(
            'click',
            'input.addSiteSetting',
            function () {
                DCMS.GUI.Forms.SiteSettingsForm.elem.append(DCMS.GUI.Forms.SiteSettingsForm.tmpl.html());
                DCMS.GUI.Forms.SiteSettingsForm.renumberFormRows();
            }
        );
    },

    renumberFormRows: function () {
        'use strict';

        var rows = this.elem.find('div.form-row');

        rows.each(function (idx, row) {
            $(row).find(':input[name*=site_setting_key_array]').attr('name', 'site_setting_key_array[' + (idx + 1) + ']');
            $(row).find('.form-field>label').text((idx + 1) + '.');
        });

    }
};

/**
 * Used in SAM Edit Plugins
 * @type {{elem: null, tmpl: null, init: DCMS.GUI.Forms.PluginMediaForm.init, renumberFormRows: DCMS.GUI.Forms.PluginMediaForm.renumberFormRows}}
 */
DCMS.GUI.Forms.PluginMediaForm = {
    elem: null,
    tmpl: null,

    init: function () {
        'use strict';

        this.elem = $('fieldset#DCMS_pluginMediaForm');
        this.tmpl = $('div#DCMS_pluginMediaDummy');

        if (!this.elem || !this.tmpl) {
            return;
        }

        // Remove Row
        $(document).on(
            'click',
            'input.delPluginMedia',
            function () {
                $(this).parents('div.DCMS_contentPair').detach();
                DCMS.GUI.Forms.PluginMediaForm.renumberFormRows();
            }
        );

        // Add Row
        $(document).on(
            'click',
            'input.addPluginMedia',
            function () {
                DCMS.GUI.Forms.PluginMediaForm.elem.append(DCMS.GUI.Forms.PluginMediaForm.tmpl.html());
                DCMS.GUI.Forms.PluginMediaForm.renumberFormRows();
            }
        );

        DCMS.GUI.Forms.PluginMediaForm.renumberFormRows();
    },

    renumberFormRows: function () {
        'use strict';
        $('div.DCMS_contentPair', this.elem).each(function (idx, row) {
            $('input[name*=plugin_media_obj_id_array]', row).attr('name', 'plugin_media_obj_id_array[' + (idx + 1) + ']').attr('id', 'form_plugin_media_obj_id_array_' + (idx + 1));
            $('select[name*=plugin_media_obj_type_array]', row).attr('name', 'plugin_media_obj_type_array[' + (idx + 1) + ']');
            $('input[name*=plugin_media_obj_sorter_array]', row).attr('name', 'plugin_media_obj_sorter_array[' + (idx + 1) + ']');
            $('input.DCMS_browseMediaDB', row).click(function () {
                // wopen('win_mce_file_browser.php?' + DCMS.Session.SID + '&type=media_id&selected_content_key=plugin_media_obj_id_array_' + (idx + 1) + '&browse_media_id=1', 'editor', 900, 480, -1, -1, 1, false);
                // /dialog_functions.php?%s&amp;dialog_reset=1&amp;selected_dialog_type=%s&amp;selected_dialog_submit=%s&amp;selected_content_key=%s%s%s
                window.wopen('/dialog_functions.php?' + DCMS.Session.SID + '&dialog_reset=1&selected_dialog_type=dt_file&selected_dialog_submit=1&selected_content_key=plugin_media_obj_id_array_' + (idx + 1), 'editor', '90%', '70%', -1, -1, 1, false);
            });
            $('label', row).text((idx + 1) + '.');
        });
    }
};

/**
 * Used for Media-Relations (site-setting "site_mediafile_relation_types")
 * @type {{elem: null, tmpl: null, init: DCMS.GUI.Forms.MediaRelationForm.init, renumberFormRows: DCMS.GUI.Forms.MediaRelationForm.renumberFormRows}}
 */
DCMS.GUI.Forms.MediaRelationForm = {
    elem: null,
    tmpl: null,

    init: function () {
        'use strict';

        this.elem = $('fieldset#DCMS_mediaRelationForm');
        this.tmpl = $('div#DCMS_mediaRelationDummy');

        if (!this.elem || !this.tmpl) {
            return;
        }

        // Remove Row
        $(document).on(
            'click',
            'input.delMediaRelation',
            function () {
                $(this).parents('div.DCMS_contentPair').detach();
                DCMS.GUI.Forms.MediaRelationForm.renumberFormRows();
            }
        );

        // Add Row
        $(document).on(
            'click',
            'input.addMediaRelation',
            function () {
                DCMS.GUI.Forms.MediaRelationForm.elem.append(DCMS.GUI.Forms.MediaRelationForm.tmpl.html());
                DCMS.GUI.Forms.MediaRelationForm.renumberFormRows();
            }
        );

        DCMS.GUI.Forms.MediaRelationForm.renumberFormRows();
    },

    renumberFormRows: function () {
        'use strict';
        $('div.DCMS_contentPair', this.elem).each(function (idx, row) {
            $('input[name*=media_relation_media_id_array]', row).attr('name', 'media_relation_media_id_array[' + (idx + 1) + ']').attr('id', 'form_media_relation_media_id_array_' + (idx + 1));
            $('select[name*=media_relation_type_array]', row).attr('name', 'media_relation_type_array[' + (idx + 1) + ']');
            $('input.DCMS_browseMediaDB', row).click(function () {
                // wopen('win_mce_file_browser.php?' + DCMS.Session.SID + '&type=media_id&selected_content_key=media_relation_media_id_array_' + (idx + 1) + '&browse_media_id=1', 'editor', 900, 480, -1, -1, 1, false);
                // /dialog_functions.php?%s&amp;dialog_reset=1&amp;selected_dialog_type=%s&amp;selected_dialog_submit=%s&amp;selected_content_key=%s%s%s
                window.wopen('/dialog_functions.php?' + DCMS.Session.SID + '&dialog_reset=1&selected_dialog_type=dt_file&selected_dialog_submit=1&selected_content_key=media_relation_media_id_array_' + (idx + 1), 'editor', '90%', '70%', -1, -1, 1, false);
            });
            $('label', row).text((idx + 1) + '.');
        });
    }
};

DCMS.GUI.Help = {
    elem: null,

    init: function () {
        'use strict';

        DCMS.GUI.Tooltip.init();

    }
};

DCMS.GUI.TimePicker = {
    elem: null,

    init: function () {
        'use strict';

        //this.elem = $('.DCMS_pickTime');
        this.elem = $('.time_input');

        if (this.elem.length > 0) {
            /*
             $('.time_input').DCMS_timeInput({
             intervalHours   : 1,
             intervalMinutes : 10
             });
             */
            this.elem.timepicker({
                showPeriodLabels: false,
                appendSeconds: true
            });
        }
    }
};

DCMS.GUI.DatePicker = {
    elem: null,

    init: function () {
        'use strict';

        this.elem = $('.date_input');

        if (this.elem.length > 0) {
            var opt_maxDate = this.elem.attr('data-maxDate') || null;

            this.elem.datepicker({
                dateFormat: "yy-mm-dd",
                changeMonth: true,
                changeYear: true,
                maxDate: opt_maxDate
            });
        }
    }
};

/**
 * AccessUserSelect - uses twitter typeahead and Bloodhound to get access user data from DCMS API
 * - gets name, email and id
 *
 * @link https://github.com/twitter/typeahead.js
 * @link https://github.com/markashleybell/mab.jquery.taginput (modified!)
 * @type {{elem: null, init: DCMS.GUI.AccessUserSelect.init}}
 */
DCMS.GUI.AccessUserSelect = {
    elem: null,
    data_elem: null,
    bloodhound: null,

    init: function () {
        this.elem = $('.tm-input');
        this.data_elem = this.elem.next('.tm-input-data');

        const _self = this;

        if (this.elem.length > 0) {


            // Instantiate the Bloodhound suggestion engine
            _self.bloodhound = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace(['name', 'email']),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                identify: function (obj) {
                    return obj.id;
                },
                prefetch: {
                    url: '/dcmsAjaxAPI.php?' + DCMS.Session.SID + '&object=access_user&object_type=auto_complete',
                    cache: true,
                    transform: function (response) {
                        return response.data;
                    },
                }
            });

            _self.bloodhound.initialize();

            // Create typeahead-enabled tag inputs
            _self.elem.tagInput({
                allowDuplicates: false,
                typeahead: true,
                typeaheadOptions: {
                    highlight: true
                },
                typeaheadDatasetOptions: {
                    name: 'users',
                    limit: 10,
                    display: function (d) {
                        return d.name + ' (' + d.email + ')';
                    },
                    source: this.bloodhound
                },
                onTagDataChanged: function (added, removed) {
                    const tag = added || removed;
                    const userId = _self.getId(tag);

                    if (added) {
                        _self.addId(userId)
                    }
                    if (removed) {
                        _self.removeId(userId);
                    }
                }
            });
        }
    },

    /**
     *
     * @param {string} tag
     * @returns {null}
     */
    getId: function (tag) {
        let userId = null;
        const userEmail = tag.match(/\((.*@.*)?\)/)[1];

        this.bloodhound.search(userEmail, function (user) {
            userId = user[0].id;
        });

        return userId;
    },

    /**
     *
     * @param {number} userId
     */
    removeId: function (userId) {
        let value = this.data_elem.val();
        let userIds = value ? value.split(',') : [];

        const index = userIds.indexOf(String(userId));
        if (index > -1) {
            userIds.splice(index, 1);
        }
        this.data_elem.val(userIds.join(','));
    },

    /**
     *
     * @param {number} userId
     */
    addId: function (userId) {
        let value = this.data_elem.val();
        let userIds = value ? value.split(',') : [];

        userIds.push(userId);
        this.data_elem.val(userIds.join(','));
    }
};

/**
 * Colorbox definitions
 *
 * @type {{init: DCMS.GUI.cbox.init}}
 */
DCMS.GUI.cbox = {
    init: function () {
        'use strict';

        // *** Init colorbox
        try {
            // *** Colorbox Default
            $('a.thickbox, a.cbox_image').colorbox();

            // *** Colorbox iframe
            $('a.cbox_iframe').colorbox({
                iframe: true,
                width: "80%",
                height: "80%"
            });

            // *** Colorbox Debug-Info
            $('a.cbox_debugInfo').colorbox({
                inline: true,
                href: "#DCMS_debugContent",
                width: "80%",
                height: "80%"
            });

            // *** Colorbox PHP-Info
            $('a.cbox_phpInfo').colorbox({
                inline: true,
                href: "#DCMS_phpInfoContent",
                width: "80%",
                height: "80%"
            });
        } catch (e) {
            DCMS.Debug.log('Init DCMS.GUI.cbox failed: ' + e);
        }
    }
};

/**
 * Slider to adjust column width in DCMS
 *
 * @type {{elem: null, init: DCMS.GUI.Slider.init}}
 */
DCMS.GUI.Slider = {

    elem: null,

    init: function () {
        'use strict';
        var slider_pos = 30;

            this.elem = $('#DCMS_sliderRatio');

            if (this.elem.length > 0) {
                if ($.cookie('DCMS_Slider')) {
                    slider_pos = $.cookie('DCMS_Slider');
                    $('#DCMS_pageContentLeft').css('width', slider_pos + '%');
                    $('#DCMS_pageContentRight').css('width', (99 - slider_pos) + '%');
                }
                this.elem.slider({
                    range: "min",
                    value: slider_pos,
                    min: 0,
                    max: 100,
                    slide: function (event, ui) {
                        $('#DCMS_pageContentLeft').css('width', ui.value + '%');
                        $('#DCMS_pageContentRight').css('width', (99 - ui.value) + '%');
                    },
                    stop: function (event, ui) {
                        $.cookie('DCMS_Slider', ui.value);
                    }
                });
            }
    }
};

/**
 * Context-Menu (RCM = Right-Click-Menu)
 * @type {{elem: null, init: DCMS.GUI.RCM.init}}
 */
DCMS.GUI.RCM = {

    elem: null,

    init: function () {
        'use strict';

        this.elem = $('.DCMS_tree, #DCMS_pluginTree, #DCMS_objectOverview, #DCMS_accessContent, a[data-rcm]');

        if (this.elem.length > 0) {
            // Right-Click-Menu (Pages)
            this.elem.DCMS_RCM(
                {
                    menuId: 'DCMS_contextMenu',
                    fx: false,
                    fxType: 'fade',
                    fxSpeed: 'slow',
                    onShow: function (e, m) {
                        m.html('');

                        var el = $(e.target);
                        var pageName, pageName_array, queryString;

                        if (el.get(0).tagName !== 'A') {
                            pageName = el.attr('title');	// title aus img tag holen
                            el = el.parents('a');
                        } else {
                            pageName = el.text();					// text zwischen a holen
                        }

                        pageName_array = pageName.split('|');
                        pageName = pageName_array[0];
                        queryString = el.attr('rel');

                        $.ajax({
                            type: "get",
                            url: "getDCMSAjaxRCM.php",
                            data: queryString + "&object_title=" + pageName,
                            dataType: 'html',

                            beforeSend: function () {
                                m.html('<div id="DCMS_ajaxLoadContent"></div>');
                            },

                            success: function (data) {
                                m.html(data);
                                $('a', m).each(function (i, e) {
                                    $(e).prepend('<i></i>');
                                });
                                DCMS.Session.refresh();
                            }
                        });
                    }
                }
            );
        }
    }
};

DCMS.GUI.Sort = {
    elem: null,

    init: function () {
        'use strict';

        this.elem = $('.DCMS-table.sortable');

        this.sortable_list = this.elem.find('tbody');

        this.sortable_list.sortable({
            placeholder: 'sortable-placeholder',
            containment: 'parent',
            items: '> tr',
            axis: 'y',
            update: function (ev, ui) {
                "use strict";

                var sorted = $(this).sortable("toArray", {attribute: "data-object-id"});
                var object_type = ui.item.data('object');
                DCMS.AJAX.sendApiRequest('sort', object_type, sorted, null, null);
            }
        });

    }
};


DCMS.GUI.Tree = {

    elem: null,
    selected_node_id: 0,

    init: function () {
        'use strict';

        this.elem = $('.DCMS_tree, #DCMS_pluginTree');

        if (this.elem.length > 0) {
            this.elem.DCMS_Tree({
                startCollapsed: true,
                expandRoot: true,
                cookie: 'auto',
                forceOpen: this.selected_node_id
            });

            // *** Show Tree
            $('.DCMS_tree').fadeIn();

        }
    },

    select: function () {
        'use strict';

        // *** Save tree and button positions for scrolling forth and back
        var $DCMS_tree = DCMS.GUI.Tree.elem;
        var buttonPos = $(this).offset();
        var treePos = $DCMS_tree.offset();
        var currentPos = $(document).scrollTop();
        var rePosition = false;

        // *** Scroll to tree
        if (treePos.top < currentPos) {
            scrollTo(treePos.left, treePos.top - 10);
            rePosition = true;
        }

        // *** Add Class for select tree
        $DCMS_tree.addClass('DCMS_treeSelect');
        $DCMS_tree.prepend('<div class="DCMS_treeSelectHint">Press &lt;ESC&gt; to Cancel</div>');

        var sft = $(this).parents('.DCMS_sftRow');

        // *** Find Parent-Name input field
        var parentNameField = sft.find('input.DCMS_sftParentName');

        // *** Find Parent-ID input field
        var parentIdField = sft.find('input.DCMS_sftParentId');

        var oldParentId = parseInt(parentIdField.val());

        var formContainer = $(this).parents('div.DCMS_formArray');

        $(document).one('keypress', function (e) {
            if (e.keyCode === 27) // Escape
            {
                // *** unbind seletion clicks from tree
                $('a', $DCMS_tree).unbind('click');

                // *** Make tree normal
                $DCMS_tree.removeClass('DCMS_treeSelect');

                // *** Scroll back to button
                if (rePosition) {
                    scrollTo(buttonPos.left, currentPos);
                }

                $('.DCMS_treeSelectHint', $DCMS_tree).detach();

            }
        });

        // *** Add click event for selecting tree node
        $('.DCMS_tree a').one('click', function (event) {
            // *** Prevent event to bubble up and href to be executed
            event.stopPropagation();
            event.preventDefault();

            // *** Get Folder ID from rel attribute of tree node
            //var parent_id = $(this).attr('rel').match(/object_id=([0-9]*)/)[1];
            var parent_id = $(this).parent('li').attr('data-tree-node-id');
            var parent_name = $(this).text();

            // *** Input Parent-Name and -ID
            parentNameField.val(parent_name);
            parentIdField.val(parent_id);

            // *** unbind seletion clicks from tree
            $('a', $DCMS_tree).unbind('click');

            // *** Make tree normal
            $DCMS_tree.removeClass('DCMS_treeSelect');

            // *** Scroll back to button
            if (rePosition) {
                scrollTo(buttonPos.left, currentPos);
            }

            $('.DCMS_treeSelectHint', $DCMS_tree).detach();
            if (oldParentId === 0 && formContainer.hasClass('selectFromTreeMultiple')) {
                DCMS.GUI.Tree.selectCallback(formContainer);
            }
        });
    },

    selectCallback: function (formContainer) {
        'use strict';

        // *** Get the Form Dummy
        var formDummy = $('.DCMS_formDummy', formContainer);

        var formClone = formDummy.clone(true, true);

        // *** Remove identifier class from form Dummy
        formDummy.removeClass('DCMS_formDummy');

        // *** Get Form Dummy HTML
        var formDummyHTML = formDummy.html();

        // *** Get Name of Array Input Field
        var name = $('input.DCMS_sftParentId', formDummy).attr('name');
        // *** Get Index of Form Dummy Field
        var matches = name.match(/(.*)\[([\d]+)\]/);
        var index = parseInt(matches[2]);

        // *** Replace Array fields in Form Dummy by new Index
        formClone.html(formDummyHTML.replace(/\[(\d+)\]/i, '[' + (index + 1) + ']'));

        // *** Append new Field to DOM
        formContainer.append(formClone);
        $('.DCMS_formDummy > label', formContainer).css('visibility', 'hidden');

        // *** Register Click Event on SFT-Button
        $('input.DCMS_btn_searchTree', formClone).click(DCMS.GUI.Tree.select);

        DCMS.GUI.Forms.ArrayForms.addDeleteButton(formDummy);
    }
};

/**
 * Tooltips
 *
 * @type {{elem: null, init: DCMS.GUI.Tooltip.init}}
 */
DCMS.GUI.Tooltip = {

    elem: null,

    init: function (selector) {
        'use strict';

        selector = selector || '.DCMS-tooltip';

        try {
            this.elem = $(selector + '[title]');
            if (this.elem.length > 0) {
                this.elem.tooltip({
                    items: selector,
                    content: DCMS.Utils.getTooltipHTML,
                    tooltipClass: 'DCMS_tooltip',
                    track: true,
                    position: {my: "left top+15", at: "left top", collision: "flipfit"}
                });
            }
        } catch (e) {
            DCMS.Debug.log('Init DCMS.GUI.Tooltips failed: ' + e);
        }

    }
};
