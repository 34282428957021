DCMS.Debug = {

	elem  : null,
	contents : null,

	items : {
		'Notice'  : null,
		'Warning' : null,
		'Error'   : null,
		'Other'   : null
	},

	init : function()
	{
		'use strict';

		this.elem = $('#DCMS_debug');
		this.contents = this.elem.find('ol.DCMS_debugMessages');

		if (this.elem.length > 0)
		{
			this.items = {
				'Notice'  : $('li:contains("E_USER_NOTICE"), li:contains("E_NOTICE")', this.contents),
				'Warning' : $('li:contains("E_WARNING"), li:contains("E_USER_WARNING")', this.contents),
				'Error'   : $('li:contains("E_USER_ERROR"), li:contains("E_STRICT")', this.contents),
				'Other'   : $('li:contains("ObjectBuffer"), li:contains("ObjectHandlingBuffer")', this.contents)
			};
			this.colorize();
			this.initTypeSelect();
		}
	},

	initTypeSelect : function()
	{
		"use strict";

		var elem = this.elem.find('.DCMS_debugTypeSelect');

		elem.find(':checkbox').on('click', function()
		{

			var type = $(this).val();
			if ($(this).prop('checked'))
			{
				// Enable type
				DCMS.Debug.items[type].show();
			}
			else
			{
				// Disable type
				DCMS.Debug.items[type].hide();
			}
		});
	},

	colorize : function()
	{
		'use strict';

		// *** Notices
		this.items.Notice.addClass('DCMS_debugNotice');

		// *** Warnings
		this.items.Warning.addClass('DCMS_debugWarning');

		// *** Errors
		this.items.Error.addClass('DCMS_debugError');

		// *** ObjectBuffer
		this.items.Other.addClass('DCMS_debugBuffer DCMS_debugOther');
	},

	/**
	 *
	 * @param msg
	 */
	log : function(msg)
	{
		'use strict';

		if (DCMS.debug && typeof console !== 'undefined')
		{
			console.log(msg);
		}
	},

	/**
	 * Output Object
	 * @param obj
	 * @return {String}
	 */
	outputObject : function(obj)
	{
		'use strict';

		var ret = '';
		for (var key in obj)
		{
			if (obj.hasOwnProperty(key))
			{
				ret = ret + key + ' = ' + obj[key] + '\n';
			}
		}
		return ret;
	}
};
