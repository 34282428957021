DCMS.Events = {

	Global : {

		autoSubmit : function()
		{
			"use strict";

			$(this).parents('form').submit();
		},

		changeSite : function()
		{
			'use strict';

			// Delete Tree-Cookies
			$.cookie('DCMS_tree', null);
			$(this).parents('form').submit();
			//$('#DCMS_mainMenuSiteSelection').find('form[name=select_site]').submit();
		},

		toggleFieldset : function()
		{
			'use strict';
			$(this).toggleClass('DCMS_foldDown').next('div').slideToggle('fast');
		},

		/**
		 * Prevent forms to be submitted twice by disabling submit buttons
		 * To disable FORM-Tag must have data-prevent-multiple-submits="off"
		 *
		 * @returns {boolean}
		 */
		preventMultipleFormSubmits : function()
		{
			"use strict";

			if ($(this).hasClass('submit_disabled'))
			{
				DCMS.Debug.log('Button disabled to prevent double-clicks');
				return false;
			}

			var test_exists = false,
				confirm_result = true,
				confirm_test_result = true,
				confirm_text = $(this).attr('data-confirm-text') || '',
				confirm_test = $(this).attr('data-confirm-test') || '';

			// *** Check if Test exists
			if (typeof DCMS.Tests[confirm_test] != 'undefined')
			{
				test_exists = true;
			}

			// *** Execute Test
			if (test_exists && typeof DCMS.Tests[confirm_test].test == 'function')
			{
				confirm_test_result = DCMS.Tests[confirm_test].test();
			}

			if (confirm_text != '' && confirm_test_result)
			{

				// *** Show confirmation dialog
				confirm_text = decodeURIComponent(confirm_text.replace(/\+/g, '%20')).replace(/\|/g, "\n");
				confirm_result = confirm(confirm_text);

				// *** Execute confirmation callback
				if (test_exists && typeof DCMS.Tests[confirm_test].callback == 'function')
				{
					DCMS.Tests[confirm_test].callback(confirm_result);
				}

				// *** Cancel submit
				if (!confirm_result)
				{
					return false
				}
			}

			$(this).find('input[type="submit"], button[type="submit"]').addClass('submit_disabled');

			return true;

		},

		selectFromTree : function(ev)
		{
			'use strict';
			DCMS.Debug.log('Select from tree');
			DCMS.GUI.Tree.select(ev);
		},

		/**
		 * Check/Uncheck all checkboxes in container
		 *
		 * @return {Boolean}
		 */
		checkAll : function()
		{
			'use strict';
			var container;
			var target_selector = $(this).attr('data-target-selector');

			if (!target_selector)
			{
				container = $(this).parents('form');
			}
			else
			{
				container = $(target_selector);
			}
			if (container.length > 0)
			{
				var checked = $(this).prop('checked');
				container.find('input:checkbox:enabled').prop('checked', checked);
				return true;
			}
			else
			{
				return false;
			}
		},

		/**
		 * Check/Uncheck all checkboxes with the same name (defined by value of checkbox)
		 */
		checkAllByName : function()
		{
			"use strict";
			var name = $(this).val();
			var checked = $(this).prop('checked');
			$('input:checkbox[name^="' + name + '"]').prop('checked', checked);
		},

		/**
		 * Sync all selects with the same name (defined by value of checkbox)
		 */
		syncSelectsByName : function()
		{
			"use strict";
			var name = $(this).val();
			var selects = $('select[name^="' + name + '"]');
			if (this.checked)
			{
				selects.on('change', function()
				{
					var value = $(this).val();
					var others = selects.filter(':not([name="' + $(this).attr('name') + '"])');
					others.val(value);
				});
			}
			else
			{
				selects.off('change');
			}

		}
	},


	Help : {
		loadHelpContent : function(ev)
		{
			'use strict';
			ev.stopPropagation();
			var page_id = $(this).parents('li[data-tree-node-id]').attr('data-tree-node-id');
			DCMS.AJAX.getHelpContent(page_id, 'content');
		}
	},

	Pages : {

		/**
		 * Change Page Type
		 */
		changePageType : function()
		{
			'use strict';

			var
				template_type,
				page_type = $(this).val(),
				pt_class = $('option[value=' + page_type + ']', $(this)).attr('class');

			$(this).attr('class', pt_class);

			switch (page_type)
			{
				case '1': // Editable Content
					template_type = 1;
					$('#tr_external_url, #tr_target, #tr_internal_url, #tr_plugin, #DCMS_pluginInitialization').hide();
					$('#tr_template, #tr_mime_type, #tr_fragment').show();
					DCMS.AJAX.getPageTypeTemplateOptions(template_type);
					break;
				case '9': // Komponente
					template_type = 9;
					$('#tr_external_url, #tr_target, #tr_internal_url').hide();
					$('#tr_template, #tr_mime_type, #tr_plugin, #DCMS_pluginInitialization').show(); // #tr_fragment
					DCMS.AJAX.getPageTypeTemplateOptions(template_type);
					break;
				case '8': // PDF
					template_type = 8;
					$('#tr_external_url, #tr_target, #tr_internal_url, #tr_plugin, #DCMS_pluginInitialization').hide();
					$('#tr_template, #tr_mime_type, #tr_fragment').show();
					DCMS.AJAX.getPageTypeTemplateOptions(template_type);
					break;
				case '2': // Internal Url; deprecated
					$('#tr_external_url, #tr_template, #tr_mime_type, #tr_plugin, #DCMS_pluginInitialization, #tr_fragment').hide();
					$('#tr_internal_url, #tr_target').show();
					$('#DCMS_pluginInitialization .form-row').html('');
					break;
				case '3': // Link
					$('#tr_internal_url, #tr_template, #tr_mime_type, #tr_plugin, #DCMS_pluginInitialization, #tr_fragment').hide();
					$('#tr_external_url, #tr_target').show();
					$('#DCMS_pluginInitialization .form-row').html('');
					break;
				case '4': // Iframe
					template_type = 1;
					$('#tr_target, #tr_internal_url, #tr_plugin, #DCMS_pluginInitialization').hide();
					$('#tr_external_url, #tr_fragment, #tr_template, #tr_mime_type').show();
					DCMS.AJAX.getPageTypeTemplateOptions(template_type);
					break;
				case '5': // Plugin
					template_type = 5;
					$('#tr_external_url, #tr_target, #tr_internal_url').hide();
					$('#tr_template, #tr_mime_type, #tr_fragment, #tr_plugin, #DCMS_pluginInitialization').show();
					$('#DCMS_pluginInitialization .form-row').html('Please select a plugin first');
					DCMS.AJAX.getPageTypeTemplateOptions(template_type);
					break;
				case '7': // Folder
					$('#tr_internal_url, #tr_template, #tr_mime_type, #tr_fragment, #tr_plugin, #tr_external_url, #tr_target, #DCMS_pluginInitialization').hide();
					$('#DCMS_pluginInitialization .form-row').html('');
					break;
			}
		},

		/**
		 * Change Page Template
		 */
		changePageTemplate : function()
		{
			'use strict';
			var page_template = $(this).val();
			var data_preview_img = $('option[value=' + page_template + ']', this).attr('data-preview-img');
			$('span#DCMS_template_preview_current a').attr('href', data_preview_img);
			$('span#DCMS_template_preview_current img').attr('src', data_preview_img);

		},

		/**
		 * Show new Template Preview Image
		 *
		 * @param ev
		 */
		changePageTemplatePreview : function(ev)
		{
			'use strict';
			var hover_obj = $(ev.target);
			var elem = $('span#DCMS_template_preview_hover img');

			if (ev.type === 'mouseover')
			{
				var data_preview_img = (hover_obj.attr('data-preview-img'));
				if (typeof data_preview_img !== 'undefined')
				{
					elem.attr('src', data_preview_img);
				}
				else
				{
					elem.attr('src', DCMS.theme_files.img.no_preview).attr('alt', 'No preview available');
				}
			}
			else
			{
				elem.attr('src', DCMS.theme_files.img.no_preview).attr('alt', '');
			}
		},

		/**
		 *
		 */
		changePlugin : function()
		{
			'use strict';
			var plugin_id = $(this).val();
			$.ajax({
				type       : 'get',
				url        : 'dcmsAjaxAPI.php',
				data       : DCMS.Session.SID + "&object=plugin_init&plugin_id=" + plugin_id + "&page_id=" + DCMS.selected_page_id,
				dataType   : 'html',
				beforeSend : function()
				{
				},
				success    : function(data)
				{
					var elem = $('#DCMS_pluginInitialization');
					elem.find('.form-row').detach();
					elem.append(data);
				}
			});
		},

		/**
		 *
		 * @deprecated
		 */
		changePluginSetting : function()
		{
			'use strict';

			var type_id = $(this).val();
			var plugin_id = $('#DCMS_selectPlugin').val();
			var i;

			$.ajax({
				type     : 'get',
				url      : "dcmsAjaxAPI.php",
				data     : DCMS.Session.SID + "&object=plugin&plugin_id=" + plugin_id + "&ajaxPluginSwitch=ajaxGetCategoryByTypeID&ajaxPluginSwitchID=" + type_id,
				dataType : 'json',
				success  : function(data, status)
				{
					var elem = $('#DCMS_pluginSetting_category_id');

					elem.html('');
					for (i in data)
					{
						elem.append('<option value="' + i + '">' + data[i] + '</option>');
					}
				}
			});
		}

	}
};
