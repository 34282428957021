DCMS.Utils = {

	init : function()
	{
		'use strict';

		// *** Compatibility for IE8 or lower
		if (!Date.now)
		{
			Date.now = function()
			{
				return new Date().getTime();
			}
		}
	},

	copyValue : function(srcId, destId)
	{
		'use strict';
		$('#' + destId).val($('#' + srcId).val());
	},

	isEmpty : function(obj)
	{
		'use strict';
		return (this.getObjectLength(obj) == 0);
	},

	/**
	 * Get number of elemets in object
	 *
	 * @param {Object} obj
	 * @returns {number}
	 */
	getObjectLength : function(obj)
	{
		"use strict";
		var i, len = 0;
		for (i in obj)
		{
			if (obj.hasOwnProperty(i))
			{
				len++;
			}
		}
		return len
	},

	getTooltipHTML : function()
	{
		var tt_title, tt_content, tt_html;
		var title = $(this).attr('title');

		if (title.indexOf('|') > 0)
		{
			var content_array = title.split('|');
			tt_title = content_array[0];
			tt_content = content_array[1];
			tt_html = '<h3>' + tt_title + '</h3>' + '<p>' + tt_content + '</p>';
		}
		else
		{
			tt_html = title;
		}

		return tt_html;
	},

	/**
	 * Extract an GET-param from URL
	 *
	 * @param {string} paramName
	 * @returns {string}
	 */
	getUrlParam : function(paramName)
	{
		var reParam = new RegExp('(?:[\?&]|&amp;)' + paramName + '=([^&]+)', 'i');
		var match = window.location.search.match(reParam);

		return (match && match.length > 1) ? match[1] : '';
	},

	/**
	 * Hide the element of the given ID
	 * @param {string} id
	 */
	hideElement : function(id)
	{
		'use strict';
		$('#' + id).hide();
	},

	Styles : {

		load : function(url)
		{
			'use strict';
			var a = document.createElement('link');
			a.setAttribute('type', 'text/css');
			a.setAttribute('rel', 'stylesheet');
			a.setAttribute('href', url);
			document.getElementsByTagName('head')[0].appendChild(a);
		}
	},

	Scripts : {

		queuedScripts : [],

		load : function(url, onload, bOrder)
		{
			'use strict';
			this.loadXhrInjection(url, onload, bOrder);
		},

		loadXhrInjection : function(url, onload, bOrder)
		{
			'use strict';
			var iQueue = DCMS.Utils.Scripts.queuedScripts.length;
			if (bOrder)
			{
				DCMS.Utils.Scripts.queuedScripts[iQueue] = {
					response : null,
					onload   : onload,
					done     : false,
					status   : null
				};
			}

			var xhrObj = DCMS.Utils.Scripts.getXHRObject();
			xhrObj.onreadystatechange = function()
			{
				if (xhrObj.readyState === 4)
				{
					if (bOrder)
					{
						DCMS.Utils.Scripts.queuedScripts[iQueue].response = xhrObj.responseText;
						DCMS.Utils.Scripts.queuedScripts[iQueue].status = xhrObj.status;
						DCMS.Utils.Scripts.inject();
					}
					else
					{
						var se = document.createElement('script');
						document.getElementsByTagName('head')[0].appendChild(se);
						se.text = xhrObj.responseText;
						if (onload)
						{
							onload();
						}
					}
				}
			};
			xhrObj.open('GET', url, true);
			xhrObj.send('');
		},

		inject : function()
		{
			'use strict';

			var len = DCMS.Utils.Scripts.queuedScripts.length;
			for (var i = 0; i < len; i++)
			{
				var qScript = DCMS.Utils.Scripts.queuedScripts[i];
				if (!qScript.done)
				{
					if (!qScript.response)
					{
						// STOP! need to wait for this response
						break;
					}
					else
					{
						if  (qScript.status === 200)
						{
							var se = document.createElement('script');
							document.getElementsByTagName('head')[0].appendChild(se);
							se.text = qScript.response;
						}
						if (qScript.onload)
						{
							qScript.onload();
						}
						qScript.done = true;
					}
				}
			}
		},

		getXHRObject : function()
		{
			'use strict';

			var xhrObj = false;
			try
			{
				xhrObj = new XMLHttpRequest();
			}
			catch (e)
			{
				var aTypes = [
					"Msxml2.XMLHTTP.6.0",
					"Msxml2.XMLHTTP.3.0",
					"Msxml2.XMLHTTP",
					"Microsoft.XMLHTTP"
				];
				var len = aTypes.length;
				for (var i = 0; i < len; i++)
				{
					try
					{
						xhrObj = new ActiveXObject(aTypes[i]);
					}
					catch (e)
					{
						continue;
					}
					break;
				}
			}
			finally
			{
				return xhrObj;
			}
		} // EO getXHRObject()
	}
};
