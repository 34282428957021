DCMS.Tests = {

	mediafileOverwrite : {

		file_input : $('#DCMS_mediaFileForm').find('input[type="file"]'),

		test : function()
		{
			return this.file_input.val();
		},

		callback : function(confirm_result)
		{
			DCMS.Debug.log('calling callback_mediafileOverwrite with parameter confirm_resul = ' + confirm_result);
			if (!confirm_result)
			{
				this.file_input.val('')
			}
		}
	}
};
