DCMS.AJAX = {

	getPackages : function()
	{
		"use strict";

		return $.ajax({
			type     : 'get',
			url      : 'dcmsAjaxAPI.php',
			data     : SID + '&object=packages',
			dataType : 'json',
			async    : false,
			success  : function(result)
			{
				DCMS.loadPackages(result.data);
			}
		});
	},

	/**
	 *
	 * @param template_type
	 */
	getPageTypeTemplateOptions : function(template_type)
	{
		'use strict';

		return $.ajax({
			type     : 'get',
			url      : 'dcmsAjaxAPI.php',
			data     : DCMS.Session.SID + '&object=template_array&object_type=page_type&object_id=' + template_type,
			dataType : 'json',
			success  : function(data)
			{

				var a, i, tpl, attr_str;
				var elem = $('#form_page_template');

				// *** Pre-Processing
				switch (template_type)
				{
					case 1 :
					case 4 :
					case 8 :
						$('#form_page_template, #DCMS_pluginInitialization .form-row').html('');
						break;
					case 5 :
						elem.html('');
						break;
				}

				for (i in data)
				{
					tpl = data[i];
					attr_str = '';

					if (!DCMS.Utils.isEmpty(tpl.attr))
					{
						for (a in tpl.attr)
						{
							attr_str += ' ' + a + '="' + tpl.attr[a] + '"';
						}
					}
					elem.append('<option value="' + i + '"' + attr_str + '>' + tpl.name + '</option>');
				}

				// *** Post-Processing
				if (template_type === 1)
				{
					$('#DCMS_selectPlugin').val('0');
				}
				DCMS.GUI.updatePageTemplatePreview();
			}
		});
	},

	/**
	 * Load Help Page Content via AJAX
	 *
	 * @param page_id
	 * @param content_key
	 */
	getHelpContent : function(page_id, content_key)
	{
		'use strict';

		if (!content_key)
		{
			content_key = 'content';
		}

		return $.ajax({
			type       : 'get',
			url        : 'getDCMSHelpContent.php',
			data       : DCMS.Session.SID + '&page_id=' + page_id + '&content_key=' + content_key,
			dataType   : 'html',
			beforeSend : function()
			{
				$('#DCMS_helpContent').html('<div id="DCMS_ajaxLoadContent"></div>');
			},
			error      : function()
			{
				$('#DCMS_helpContent').html('Sorry, the help page cannot be loaded.');
			},
			success    : function(data)
			{
				var content_wrap = $('#DCMS_helpContent');
				content_wrap.html(data);
				$('.DCMS_menuSelected').removeClass('DCMS_menuSelected');
				$('a[rel=' + page_id + ']', '#DCMS_helpTree').addClass('DCMS_menuSelected');
				// tb_init('#DCMS_helpContent a.thickbox');
				$('a.thickbox').colorbox();

				// *** GHA, 2014-11-14: Resolve internal links
				$('a[href^="Link2PageID#"]', content_wrap).on('click', function(ev)
				{
					ev.preventDefault();
					var href = $(this).attr('href');
					var page_id = href.split('#')[1];
					DCMS.AJAX.getHelpContent(page_id, content_key);
				});
			}
		});

	},

	/**
	 * Send an AJAX-request to the DCMS-AJAX-API
	 *
	 * @param object
	 * @param object_type
	 * @param object_id
	 * @param success_callback
	 * @param before_callback
	 */
	sendApiRequest : function(object, object_type, object_id, success_callback, before_callback)
	{
		"use strict";
		return $.ajax({
			type       : 'get',
			url        : 'dcmsAjaxAPI.php',
			data       : DCMS.Session.SID + '&object=' + object + '&object_type=' + object_type + '&object_id=' + object_id,
			dataType   : 'json',
			success    : success_callback || function()
			{
			},
			beforeSend : before_callback || function()
			{
			}
		});
	},

	/**
	 *
	 * @param object
	 * @param object_type
	 * @param object_id
	 * @param success_callback
	 * @param before_callback
	 */
	sendApiRequestPost : function(object, object_type, object_id, success_callback, before_callback)
	{
		"use strict";
		return $.ajax({
			type       : 'post',
			url        : 'dcmsAjaxAPI.php?' + DCMS.Session.SID,
			data       : {
				'PHPSESSID'   : DCMS.Session.id,
				'object'      : object,
				'object_type' : object_type,
				'object_id'   : object_id
			},
			dataType   : 'json',
			success    : success_callback || function()
			{
			},
			beforeSend : before_callback || function()
			{
			}
		});
	}
};
