/**
 * DCMS-Session
 */
DCMS.Session = {

	name : null,
	id   : null,
	SID  : '',

	user_id      : 0, // Set by initUserOnlineCheck()
	auto_refresh : 0, // Interval for refreshing Session; use 0 to switch off

	last_refresh_time : Date.now(),
	last_user_action  : 0,

	init : function()
	{
		'use strict';

		DCMS.Localization.loadLocalization(['txt_gui_session_alert_text', 'txt_gui_dialog_title_attention']);

		this.SID = this.name + '=' + this.id;

		this.counter.init();
		this.autoRefresh.init();
	},

	/**
	 * Session-Counter
	 */
	counter : {

		interval  : 0,
		elem      : null, // Main Container
		timeLeft  : 0,
		idleTime  : 0,
		warnLevel : 20, // percent of session lifetime
		showWarn  : false,
		showAlert : true,
		lifeTime  : 0,

		// *** Initialize Session-Counter
		init : function()
		{
			'use strict';

			this.elem = $('#DCMS_sessionCounter');

			if (this.elem.length === 0)
			{
				return;
			}

			if ($('i', this.elem).length === 0)
			{
				this.elem.prepend('<i class="p100"></i>');
			}
			if (this.elem.length > 0)
			{
				this.lifeTime = parseInt(this.elem.attr('data-session-lifetime'));
				this.interval = window.setInterval(function()
				{
					DCMS.Session.counter.decr();
				}, 1000);
			}
		},

		// *** Decrease Session-Counter
		decr : function()
		{
			'use strict';

			this.idleTime += 1;
			this.timeLeft = this.lifeTime - this.idleTime;

			var
				perc = Math.ceil(this.timeLeft * 100 / this.lifeTime),
				min = Math.floor(this.timeLeft / 60),
				sec = this.timeLeft % 60;

			// *** Refresh visual counter
			if (perc % 20 === 0)
			{
				this.elem.removeClass('p' + (perc + 20)).addClass('p' + perc);
			}

			// *** Refresh Display
			$('span', this.elem).text(min + ':' + (sec < 10 ? '0' + sec : sec));

			// *** Set Warn level or logout
			if (perc < this.warnLevel && !this.showWarn)
			{
				this.showWarn = true;
				this.elem.addClass('alert');
				// *** Drop a message
				if (this.showAlert)
				{
					DCMS.GUI.Dialog.setMessage(DCMS.Localization.getLocalization('txt_gui_session_alert_text'));
					DCMS.GUI.Dialog.open({
						modal   : true,
						title   : DCMS.Localization.getLocalization('txt_gui_dialog_title_attention'),
						buttons : [
							{
								text  : "OK",
								click : function()
								{
									$(this).dialog("close");
								}
							}
						]
					});
				}
			}
			else if (this.timeLeft <= 0)
			{
				window.location.href = '/basic_functions.php?' + this.SID + '&ops=ops-basic-logout';
			}
		},

		// *** Reset Session-Counter (called by DCMS.Session.refresh)
		reset : function()
		{
			'use strict';
			this.idleTime = 0;
			this.elem.removeClass('alert p20 p40 p60 p80');
		}
	}, // End Counter

	/**
	 * Refresh Session by System-Call to dcmsAjaxAPI
	 */
	refresh : function()
	{
		'use strict';

		DCMS.AJAX.sendApiRequest('dcms_user', 'user_obj', this.user_id, function()
		{
			DCMS.Session.last_refresh_time = Date.now();
			DCMS.Session.counter.reset();
		});
	},

	/**
	 * Session-Refresh
	 */
	autoRefresh : {
		init : function()
		{
			'use strict';

			if (DCMS.Session.user_id > 0)
			{
				// *** User always online
				if (DCMS.Session.auto_refresh > 0)
				{
					setInterval(function()
					{
						DCMS.Session.refresh();
					}, DCMS.Session.auto_refresh * 1000);
				}
				else
				{
					// *** Check user actions
					setInterval(function()
					{
						DCMS.Session.checkUserAction();
					}, 60 * 1000);
				}
			}
		}
	},

	/**
	 * Compare timestamps last user action with page load and refresh session if required
	 */
	checkUserAction : function()
	{
		//DCMS.Debug.log(this.last_user_action + ' ' + this.last_refresh_time);
		if (this.last_user_action > this.last_refresh_time)
		{
			this.refresh();
		}
	},

	/**
	 * Set Timestamp of last user action
	 */
	setLastUserAction : function()
	{
		this.last_user_action = Date.now();
		//DCMS.Debug.log('Last User action: ' + DCMS.Session.last_user_action);
	}
};
