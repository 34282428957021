/**
 * @author $Author$
 * @version $Revision$
 * @package dcms
 */


/**
 * Global DCMS Namespace
 */
var DCMS = {

	debug : true,
	use_foundation: false,

	theme_files : {
		css : {},
		img : {},
		js  : {}
	},

	load : function()
	{
		"use strict";
		//DCMS.AJAX.getPackages();
		DCMS.GUI.Editor.addConfigsToThemeFiles();
		DCMS.loadPackages();
	},

	/**
	 *
	 * @param packages
	 */
	loadPackages : function(packages)
	{
		'use strict';

		var cnt = 1, p, len = 0, url;
		if (packages)
		{
			len = DCMS.Utils.getObjectLength(packages);

			for (p in packages)
			{
				DCMS.Debug.log('Load package "' + packages[p].name + '"');
				url = packages[p].css;
				if (url)
				{
					DCMS.Utils.Styles.load(url);
				}

				url = packages[p].js;
				if (url)
				{
					if (cnt < len)
					{
						DCMS.Utils.Scripts.load(url, null, true);
					}
					else
					{
						DCMS.Utils.Scripts.load(url, DCMS.init, true);
					}
				}
				cnt++;
			}
		}
		else
		{

			// *** Load CSS files
			if (DCMS.theme_files.css)
			{

				// *** Load additional CSS files
				for (p in DCMS.theme_files.css)
				{
					url = DCMS.theme_files.css[p];
					DCMS.Utils.Styles.load(url);
				}
			}

			// *** Load JavaScript files
			if (DCMS.theme_files.js)
			{
				// ** Get length of object
				len = DCMS.Utils.getObjectLength(DCMS.theme_files.js);

				// *** Load additional JS files
				for (p in DCMS.theme_files.js)
				{
					if (DCMS.theme_files.js[p] !== '')
					{

						url = DCMS.theme_files.js[p];

						// *** Call init() when last script was loaded
						if (cnt++ < len)
						{
							DCMS.Utils.Scripts.load(url, null, true);
						}
						else
						{
							DCMS.Utils.Scripts.load(url, DCMS.init, true);
						}
					}
				}
			}
		}
	},

	init : function()
	{
		DCMS.Debug.log('Start DCMS');
		DCMS.Debug.init();
		DCMS.Utils.init();
		DCMS.Session.init();
		DCMS.Notification.init();
		DCMS.GUI.init();
		if (this.use_foundation)
		{
			$(document).foundation();
		}
		PR.prettyPrint();
	}
};


/**
 * Start the DCMS ;-)
 */
$(document).ready(function()
{
	'use strict';
	DCMS.load();
});

