/**
 * DCMS-Notifications
 *
 * @type {{}}
 */
DCMS.Notification = {

	counter      : 0,
	auto_refresh : 60,

	cnt_elem  : null,
	main_elem : null,
	list_elem : null,
	view_elem : null,

	init : function()
	{
		"use strict";

		this.cnt_elem = $('#DCMS_notificationCounter');
		this.main_elem = $('.DCMS_userNotifications');
		this.list_elem = $('.DCMS_userNotificationList', this.main_elem);
		this.view_elem = $('.DCMS_userNotificationView', this.main_elem);

		this.getLocalization();
		this.updateCounter();
		this.autoRefresh.init();

		// *** Delete all
		$('th.trash i', this.list_elem).on('click', function()
		{
			var notification_id_array = [];
			var icon = $(this);
			notification_id_array[0] = 'all';
			DCMS.AJAX.sendApiRequest('dcms_user', 'notification_delete', notification_id_array,
				function(result)
				{
					if (result.success)
					{
						$.each(result.data, function(idx, val)
						{
							if (val)
							{
								DCMS.Notification.removeRow(idx);
							}
						});
						icon.removeClass('loading');
					}
				},
				function()
				{
					icon.addClass('loading');
				});
		});

		// *** Delete row
		$('td.trash i', this.list_elem).on('click', function()
		{
			var row = $(this).parents('tr');
			if (row.hasClass('isNew'))
			{
				alert(DCMS.Localization.getLocalization('txt_notification_err_not_read'));
				return;
			}

			var notification_id = row.attr('data-notification-id');
			DCMS.AJAX.sendApiRequest('dcms_user', 'notification_delete', notification_id, function(result)
			{
				if (result.success)
				{
					DCMS.Notification.removeRow(notification_id);
				}
			});
		});

		// *** Mark all read
		$('th.read i', this.list_elem).on('click', function()
		{

			var notification_id_array = [];
			var icon = $(this);
			notification_id_array[0] = 'all';
			DCMS.AJAX.sendApiRequest('dcms_user', 'notification_read', notification_id_array,
				function(result)
				{
					// Success callback
					if (result.success)
					{
						$.each(result.data, function(idx, val)
						{
							if (val)
							{
								DCMS.Notification.updateRow(idx);
							}
						});
						icon.removeClass('loading');
					}
				},
				function()
				{
					icon.addClass('loading');
				});
		});

		// *** Mark row read
		$('td.read i', this.list_elem).on('click', function()
		{
			var row = $(this).parents('tr');

			if (!row.hasClass('isNew'))
			{
				return;
			}

			var notification_id = row.attr('data-notification-id');
			DCMS.AJAX.sendApiRequest('dcms_user', 'notification_read', notification_id, function(result)
			{
				if (result.success)
				{
					DCMS.Notification.updateRow(notification_id);
				}
			});
		});
	},

	/**
	 *
	 * @param counter
	 */
	updateCounter : function(counter)
	{

		if (typeof counter != 'undefined')
		{
			this.counter = counter;
		}
		else if (typeof counter == 'undefined' && this.list_elem.length > 0)
		{
			this.counter = $('tr.isNew', this.list_elem).length;
		}
		else
		{
			return;
		}
		var old_title = this.cnt_elem.attr('title');
		var new_title = old_title.replace(/\d+/, this.counter);
		this.cnt_elem.attr('title', new_title).find('span').text(this.counter);
	},

	/**
	 *
	 * @param notification_id
	 */
	updateRow : function(notification_id)
	{
		var row = this.list_elem.find('tr[data-notification-id=' + notification_id + ']');
		row.removeClass('isNew');
		this.updateCounter();
	},

	/**
	 *
	 * @param notification_id
	 */
	removeRow : function(notification_id)
	{
		var row = this.list_elem.find('tr[data-notification-id=' + notification_id + ']');
		if (row.hasClass('selected'))
		{
			this.view_elem.html('');
		}
		row.detach();
	},

	/**
	 *
	 */
	getLocalization : function()
	{
		var localization_key_array = [
			'txt_notification_err_not_read',
			'txt_notification_counter_tooltip'
		];

		//DCMS.Localization.getLocalization('txt_notification_err_not_read');
		//DCMS.Localization.getLocalization('txt_notification_counter_tooltip');
		DCMS.Localization.loadLocalization(localization_key_array);
	},

	/**
	 *
	 */
	refresh : function()
	{
		'use strict';

		DCMS.AJAX.sendApiRequest('dcms_user', 'notification_count', null, function(result)
			{
				if (result.success)
				{
					DCMS.Notification.updateCounter(result.data);
				}
				DCMS.Notification.cnt_elem.find('i').removeClass('loading');
			},
			function()
			{
				DCMS.Notification.cnt_elem.find('i').addClass('loading');
			});
	},


	autoRefresh : {
		init : function()
		{
			'use strict';

			setInterval(function()
			{
				DCMS.Notification.refresh();
			}, DCMS.Notification.auto_refresh * 1000);
		}
	}

};
