/**
 *
 * HELPER
 *
 */

String.prototype.trim = function()
{
	'use strict';
	return this.replace(/^\s+|\s+$/g, "");
};
String.prototype.ltrim = function()
{
	'use strict';
	return this.replace(/^\s+/, "");
};
String.prototype.rtrim = function()
{
	'use strict';
	return this.replace(/\s+$/, "");
};
String.prototype.countWords = function()
{
	let s = this.replace(/(^\s*)|(\s*$)/gi,"").replace(/[ ]{2,}/gi," ").replace(/\n /,"\n");
	return s.split(' ').length;
};
String.prototype.countChars = function()
{
	return this.length;
};
jQuery.fn.debug = function()
{
	'use strict';
	return this.each(function()
	{
		alert(this);
	});
};

jQuery.log = function(message)
{
	'use strict';
	if (window.console)
	{
		console.debug(message);
	}
	else
	{
		alert(message);
	}
};
