/**
 *
 * @param image_url
 * @deprecated
 */
function DCMS_replaceImagePreview(image_url)
{
	'use strict';
	$('#DCMS_imagePreview').attr('src', image_url);
}

/**
 *
 *
 * @deprecated CSS-Class Only used in kmdcms2011_backup
 */
function DCMS_mediaInfo()
{
	'use strict';
	$('div.DCMS_mediaFolderObject').hover(
		function(event) {
			event.stopPropagation();
			$('div.DCMS_mediaFileInfo span', this).show();
		},
		function(event) {
			event.stopPropagation();
			$('div.DCMS_mediaFileInfo span', this).hide();
		}
	);
}

/**
 *
 * @param id
 * @deprecated
 */
function hideByID(id)
{
	'use strict';
	DCMS.Utils.hideElement(id);
}

/**
 *
 * @deprecated
 * @param srcId
 * @param destId
 */
function copyValue(srcId, destId)
{
	'use strict';
	DCMS.Utils.copyValue(srcId, destId);
}

/**
 *
 * @param f
 * @param c
 * @return {Boolean}
 */
function tb_sendform(f, c)
{
	'use strict';
	f.action.match(/(\bkeepThis=(true|false)&TB_iframe=true.+$)/);
	tb_show(c, 'about:blank?' + RegExp.$1);
	f.target = $('#TB_iframeContent').attr('name');
	return true;
}

/**
 * tinyMCE: set the iframe height according to the rows-attribute of the corresponding textarea
 * this function is calles from the oninit-Option in the tinyMCE-Settings
 */
function tinyMCE_customInit()
{
	'use strict';
	$('textarea.mceEditor').each(function(idx) {
		var rows = $(this).attr('rows');
		if (!rows)
		{
			return false;
		}

		var line_height = 40;
		var id = $(this).attr('id');
		var height_firstRow = $('tr.mceFirst', 'table#' + id + '_tbl').height();
		var height_lastRow = $('tr.mceLast', 'table#' + id + '_tbl').height();
		var height_tbl = (rows * line_height) + height_firstRow + height_lastRow;
		var height_ifr = rows * line_height;
		$('table#' + id + '_tbl').height(height_tbl);
		$('iframe#' + id + '_ifr').height(height_ifr);
	});
}

/**
 * open window with following parameters
 *
 * @param {string} link
 * @param {string} wname
 * @param {string|number} xsize
 * @param {string|number} ysize
 * @param {number} xpos
 * @param {number} ypos
 * @param {string} scrollbars
 * @param {bool} return_false
 * @return {bool} return_false (false)
 */

function wopen(link, wname, xsize, ysize, xpos, ypos, scrollbars, return_false)
{
	var factor = 1;

	if (scrollbars === '' || scrollbars === false)
	{
		scrollbars = 'no';
	}

	if (xsize === -1)
	{
		xsize = screen.availWidth - 10;
	}
	else if (typeof xsize === 'string' && xsize.indexOf('%'))
	{
		factor = parseInt(xsize) / 100.0;
		xsize = Math.floor(screen.availWidth * factor);
		xpos = -1
	}

	if (ysize === -1)
	{
		ysize = screen.availHeight - 60;
	}
	else if (typeof ysize === 'string' && ysize.indexOf('%'))
	{
		factor = parseInt(ysize) / 100.0;
		ysize = Math.floor(screen.availHeight * factor);
		ypos = -1
	}
	if (xpos === -1)
	{
		xpos = (screen.availWidth - xsize) / 2;
	}
	if (ypos === -1)
	{
		ypos = (screen.availHeight - ysize) / 2;
	}

	var features = 'width=' + xsize + ',height=' + ysize + ',status=no,scrollbars=' + scrollbars + ',noresizable,top=' + ypos + ',left=' + xpos + ',screenY=' + ypos + ',screenX=' + xpos;

	var nwindow = open(link, wname, features);
	nwindow.focus();

	if (return_false)
	{
		return false;
	}
}

/**
 * open new window with following parameters, all further settings active
 *
 * @param {string} link
 * @param {string} wname
 * @param {number} xsize
 * @param {number} ysize
 * @param {number} xpos
 * @param {number} ypos
 * @param {bool} return_false
 * @return {bool} return_false (false)
 */

function wopenfull(link, wname, xsize, ysize, xpos, ypos, return_false)
{
	if (xsize === -1)
	{
		xsize = screen.availWidth - 10;
	}
	if (ysize === -1)
	{
		ysize = screen.availHeight - 60;
	}
	if (xpos === -1)
	{
		xpos = (screen.availWidth - xsize) / 2;
	}
	if (ypos === -1)
	{
		ypos = (screen.availHeight - ysize) / 2;
	}

	var features = 'width=' + xsize + ',height=' + ysize + ',status=no,location=yes,directories=no,menubar=yes,toolbar=yes,status=yes,scrollbars=yes,resizable=yes,dependent=no';

	var nwindow = open(link, wname, features);
	nwindow.focus();

	if (return_false)
	{
		return false;
	}
}
