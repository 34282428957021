/**
 * Localization
 *
 * @type {{}}
 */
DCMS.Localization = {
	localization_value_array : {},

	/**
	 *
	 * @param localization_key
	 * @returns {*}
	 */
	getLocalization : function(localization_key)
	{
		if (typeof this.localization_value_array[localization_key] == 'undefined')
		{
			// *** Todo: load localization key first, then return (!async ajax request)
		}
		else
		{
			return this.localization_value_array[localization_key];
		}
	},

	/**
	 *
	 * @param localization_key
	 */
	loadLocalization : function(localization_key)
	{
		if (typeof this.localization_value_array[localization_key] == 'undefined')
		{
			return DCMS.AJAX.sendApiRequestPost('localization', 'key', localization_key, function(result)
			{
				if (typeof result.data == 'object')
				{
					for (var key in result.data)
					{
						DCMS.Localization.localization_value_array[key] = result.data[key];
					}
				}
				else
				{
					DCMS.Localization.localization_value_array[localization_key] = result.data;
				}
			});
		}
	}
};

